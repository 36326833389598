import { flex, grid } from "~/design/generated/patterns";
import { Text } from "./Text";
import { css, cx } from "~/design/generated/css";
import { ButtonLink } from "./ButtonLink";
import { Link } from "./Link";
import { Image } from "@unpic/react";
import { useAppearance } from "~/hooks/useAppearance";

const images = [
  "https://hodinkee.imgix.net/uploads/images/7df4ca9a-9f03-4a27-b6c4-c5f45e5bf8d3/CCBrandSpotlight_Rolex_PreOwned_Shot1_148_Full_JG.jpg?auto=format&w=150",
  "https://hodinkee.imgix.net/uploads/images/ac2db7ea-3798-488c-a927-727184a3aac7/Raymond-Weil-x-Basquiat-Chronograph_Lifestyle_012_SQ_KM.jpg?auto=format&w=150",
  "https://hodinkee.imgix.net/uploads/images/510e9ad9-1af6-43cf-8f70-0934dfb94f50/Omega_OME-F1C-XBQ_O21032422010001_Lifestyle_028_SQ_JG.jpg?auto=format&w=150",
  "https://hodinkee.imgix.net/uploads/images/7df4ca9a-9f03-4a27-b6c4-c5f45e5bf8d3/CCBrandSpotlight_Rolex_PreOwned_Shot1_148_Full_JG.jpg?auto=format&w=150&t=1",
];

type ExploreCardProps = {
  imageUrl: string;
};

function ExploreCard({ imageUrl }: ExploreCardProps) {
  return (
    <div
      className={cx(
        css({
          minW: "250px",
          w: "full",
          aspectRatio: 0.85,
          overflow: "hidden",
          rounded: "lg",
          position: "relative",
          cursor: "pointer",
          scrollSnapAlign: "start",
          _after: {
            background: "linear-gradient(to top, token(colors.elevation.page) 0%, transparent 100%)",
            content: '""',
            position: "absolute",
            height: "50%",
            zIndex: 3,
            right: 0,
            left: 0,
            bottom: 0,
          },
        }),
        "group",
      )}
    >
      <Image
        src={imageUrl}
        layout="fullWidth"
        aspectRatio={0.85}
        cdn="imgix"
        priority={true}
        className={css({
          zIndex: 2,
          position: "absolute",
          inset: 0,
          transition: "200ms",
          _groupHover: { transform: "scale(1.05)" },
        })}
      />
      <div
        className={flex({
          direction: "column",
          position: "absolute",
          inset: 0,
          zIndex: 4,
          p: { base: 2, md: 6 },
          justifyContent: "flex-end",
          gap: 3,
        })}
      >
        <Text variant="caption-2" color="white">
          Featured
        </Text>
        <Text variant="heading-4" color="white.soft">
          Luxury timepiece
        </Text>
      </div>
    </div>
  );
}

export function ExploreInsert() {
  return (
    <div
      className={flex({
        gap: {
          base: 4,
          md: 5,
        },
        direction: {
          base: "column",
          md: "row",
        },
      })}
    >
      <div
        className={flex({
          direction: "column",
          justifyContent: "space-between",
          alignItems: "space-between",
          w: "20%",
          minW: "200px",
          display: { base: "none", md: "flex" },
        })}
      >
        <Text variant="heading-3">
          Explore the <br /> Dials database
        </Text>

        <div className={flex({ direction: "column", gap: 2 })}>
          <Link to="/listings">New arrivals</Link>
          <Link to="/watches">Vintage</Link>
          <Link to="/watches">Trending watches</Link>
          <ButtonLink className={css({ mt: 4 })} to="/watches/audemars-piguet" variant="basic" endSlot="arrow-right">
            See all
          </ButtonLink>
        </div>
      </div>

      <Text
        variant="heading-3"
        className={css({
          display: { base: "auto", md: "none" },
        })}
      >
        Explore the Dials database
      </Text>
      <div
        data-color-mode="dark"
        className={grid({
          gap: { base: 2, md: 4 },
          flex: 1,
          overflowX: "auto",
          width: "100%",
          gridTemplateColumns: "repeat(4, minmax(250px, 1fr))",
          scrollSnapType: "x mandatory",
        })}
      >
        {images.map((imageUrl) => (
          <ExploreCard imageUrl={imageUrl} key={imageUrl} />
        ))}
      </div>

      <ButtonLink
        className={css({
          display: { base: "auto", md: "none" },
        })}
        to="/watches?brand.slug=audemars-piguet"
        variant="basic"
        endSlot="arrow-right"
      >
        See all
      </ButtonLink>
    </div>
  );
}
