import { Fragment } from "react/jsx-runtime";
import { typedjson } from "remix-typedjson";
import { ExploreInsert } from "~/components/ExploreInsert";
import { HomeBanner } from "~/components/HomeBanner";
import { WatchBrandsCarousel } from "~/components/WatchBrandsCarousel";

export async function loader({}: LoaderArgs) {
  return typedjson({});
}

const items = [
  { slug: "audemars-piguet" },
  { slug: "seiko" },
  { slug: "rolex" },
  { slug: "zenith" },
  { slug: "a-lange-and-sohne" },
  { slug: "piaget" },
  { slug: "vacheron-constantin" },
];

export default function HomePage() {
  return (
    <Fragment>
      <HomeBanner />
      <ExploreInsert />
      <WatchBrandsCarousel items={items} />
    </Fragment>
  );
}
