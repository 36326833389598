import type { SVGProps } from "react";
import { css, cva, cx } from "~/design/generated/css";
import { type AssetToken, type Token, token } from "~/design/generated/tokens";

type ExtractIconName<T> = T extends `brandLogos.${infer Substring}` ? Substring : never;
export type LogoName = ExtractIconName<AssetToken>;

export type WatchBrandLogoProps = SVGProps<SVGSVGElement> & {
  name: LogoName;
  className?: string;
};

const watchBrand = cva({
  base: {
    width: "100%",
    fill: "currentColor",
    flexShrink: 0,
  },
  variants: {
    name: {
      "audemars-piguet": {
        px: "5%",
      },
      "a-lange-and-sohne": {
        px: "5%",
      },
      "vacheron-constantin": {
        px: "5%",
      },
      seiko: {
        px: "13%",
      },
      piaget: {
        px: "20%",
      },
      rolex: {
        px: "23%",
      },
      zenith: {
        px: "20%",
      },
    },
  },
});

export function WatchBrandLogo({ name, className, ...props }: WatchBrandLogoProps) {
  return (
    <svg {...props} className={cx(watchBrand({ name }), className)}>
      <use href={token(`assets.brandLogos.${name}` as Token)} />
    </svg>
  );
}
