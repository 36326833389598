import { Text } from "./Text";
import { flex } from "~/design/generated/patterns";
import { useSetAtom } from "jotai";
import { searchAtom } from "./MeiliesearchSearchbar";
import { ButtonLink } from "./ButtonLink";

export function HomeBanner() {
  const setSearchOpen = useSetAtom(searchAtom);

  return (
    <div
      className={flex({
        minH: "400px",
        h: "50vh",
        justifyContent: "center",
        direction: "column",
        gap: 8,
      })}
    >
      <Text variant="heading-1" as="h1">
        The most complete <br /> watch database.
      </Text>
      <ButtonLink to="/watches/seiko" size="md">
        Explore models
      </ButtonLink>
    </div>
  );
}
