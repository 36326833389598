import { sva } from "~/design/generated/css";
import { type LogoName, WatchBrandLogo } from "./WatchBrandLogo";
import { Link } from "@remix-run/react";

const carousel = sva({
  slots: ["slider", "slideTrack", "slide"],
  base: {
    slider: {
      height: "150px",
      width: "full",
      overflow: "hidden",
      position: "relative",
      maskImage:
        "linear-gradient(to right, transparent 0px, black 200px, black calc(100% - 200px), transparent calc(100% - 0px))",
    },
    slideTrack: {
      animation: "scrollInfinite 40s linear infinite",
      display: "flex",
      width: "calc(var(--item-size) * var(--items-count) * 2)",
      _hover: {
        animationPlayState: "paused",
      },
    },
    slide: {
      width: "var(--item-size)",
      color: "accent.foreground",
      transition: "all 150ms ease-out",
      px: 4,
      _hover: {
        color: "neutral.11",
        cursor: "pointer",
      },
    },
  },
});

type WatchBrandsCarouselProps = {
  items: { slug: string }[];
};

export function WatchBrandsCarousel({ items }: WatchBrandsCarouselProps) {
  const classes = carousel({});
  const ITEMS = items.concat(items);

  return (
    <div
      className={classes.slider}
      style={
        {
          "--items-count": ITEMS.length / 2,
          "--item-size": "220px",
        } as React.CSSProperties
      }
    >
      <div className={classes.slideTrack}>
        {ITEMS.map(({ slug }, i) => {
          return (
            <Link to={`/watches/${slug}`} key={`${slug}-${i}`}>
              <div className={classes.slide}>
                <WatchBrandLogo name={slug as LogoName} />
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
